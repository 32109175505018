

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class fansPane extends Vue {
    $refs!: {
        valueRef: any
    }
    @Prop() value: any
    @Prop() pager!: any
}
